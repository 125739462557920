import { IFooterCard, IImage, ILink } from '../../types/custom'

export interface IBlogPostTemplate {
  type: BlogPostTemplate
  color: string
}

export interface IBlogPostInformation {
  prismicId?: string
  primary?: boolean
  uid: string
  title: string
  categories: string[]
  description: string
  mainImage: IImage
  thumbnailImage: IImage
  introductionText: string
  date: string
  template?: IBlogPostTemplate
  parent: {
    uid: string
    name: string
    parent?: {
      uid: string
      name: string
    }
  }
}

export interface IBlogPostTitle {
  value: string
}

export interface IBlogPostSubitle {
  value: string
}

export interface IBlogPostText {
  value: string
}

export interface IBlogPostStrongText {
  value: string
}

export interface IBlogPostImage extends IImage {}

export interface IBlogPostVideo {
  embed: string
  description: string
}

export interface IBlogPostAudio {
  embed: string
}

export interface IBlogPostQuote {
  value: string
  author: string
}

export interface IBlogPostCarousel {
  items: IImage[]
}

export interface IBlogPostListedBlock {
  icon: IImage
  title: string
  text: string
}

export interface IBlogPostWhitePaper {
  uid: string
  title: string
  text: string
  image: IImage
  documentUrl: string
}

export interface IBlogPostIFrame {
  html?: string
}

export interface IBlogPostQuoteCarousel {
  quotes: Array<{
    photo: IImage
    quoteText: string
    quoteAuthor: string
  }>
}

export interface IBlogPostLogoList {
  logos: Array<{
    image: IImage
    link?: ILink
  }>
}

export interface IBlogPostButtonLink extends ILink {}

export interface IBlogPostSlice {
  type: BlogPostSliceType
  content:
    | IBlogPostTitle
    | IBlogPostSubitle
    | IBlogPostText
    | IBlogPostStrongText
    | IBlogPostImage
    | IBlogPostVideo
    | IBlogPostAudio
    | IBlogPostQuote
    | IBlogPostCarousel
    | IBlogPostListedBlock
    | IBlogPostWhitePaper
    | IBlogPostButtonLink
    | IBlogPostQuoteCarousel
    | IBlogPostLogoList
    | IBlogPostIFrame
    | IFooterCard[]
}

export enum BlogPostSliceType {
  TITLE = 'TITLE',
  SUBTITLE = 'SUBTITLE',
  TEXT = 'TEXT',
  STRONG_TEXT = 'STRONG_TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  QUOTE = 'QUOTE',
  CAROUSEL = 'CAROUSEL',
  LISTED_BLOCK = 'LISTED_BLOCK',
  WHITE_PAPER = 'WHITE_PAPER',
  BUTTON_LINK = 'BUTTON_LINK',
  FOOTER_CARDS = 'FOOTER_CARDS',
  QUOTE_CAROUSEL = 'QUOTE_CAROUSEL',
  LOGO_LIST = 'LOGO_LIST',
  IFRAME = 'IFRAME',
}

export enum BlogPostTemplate {
  EDMILL = 'EDMILL',
  IFSIMULATION = 'IFSIMULATION',
}
