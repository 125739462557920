/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/camelcase */
import {
  Maybe,
  PrismicBlogPostPostsecondarycategoriesGroupType,
  TemplateBlogPostDataQuery,
} from '../../../gatsby-types'

import { BlogPostSliceType, BlogPostTemplate, IBlogPostTemplate, IBlogPostInformation, IBlogPostSlice } from './types'

export function transformAllBlogPostNode(
  nodes: TemplateBlogPostDataQuery['allPrismicBlogPost']['nodes']
): IBlogPostInformation[] {
  if (!nodes) throw new Error('Invalid blog_post nodes.')

  return nodes.map((node) => {
    if (!node) throw new Error('Invalid blog_post nodes.')

    const { uid, data } = node
    if (!uid || !data) throw new Error('Invalid blog_post nodes.')

    const {
      posttitle,
      postcategory,
      postsecondarycategories,
      posttemplate,
      postdescription,
      postmainimage,
      postthumbnailimage,
      postintroductiontext,
      postdate,
      parentdocument,
      primary,
    } = data

    if (
      !posttitle ||
      !posttitle.text ||
      !postcategory ||
      !postsecondarycategories ||
      !postdate ||
      !parentdocument ||
      !parentdocument.uid ||
      !parentdocument.document ||
      !parentdocument.document.data ||
      !parentdocument.document.data.name ||
      !parentdocument.document.data.name.text
    ) {
      throw new Error(`Invalid blog_post node : ${uid}.`)
    }

    return {
      uid,
      primary: primary || false,
      title: posttitle.text,
      categories: getCategories(postcategory, postsecondarycategories),
      description: postdescription?.html || '',
      mainImage: { alt: postmainimage?.alt || '', url: postmainimage?.url || '' },
      thumbnailImage: { alt: postthumbnailimage?.alt || '', url: postthumbnailimage?.url || '' },
      introductionText: postintroductiontext?.html || '',
      date: postdate,
      template: getTemplate(posttemplate),
      parent: {
        uid: parentdocument.uid,
        name: parentdocument.document.data.name.text,
      },
    }
  })
}

export function transformBlogPostNode(
  node: TemplateBlogPostDataQuery['prismicBlogPost']
): IBlogPostInformation & { slices: IBlogPostSlice[] } {
  if (!node) throw new Error('Invalid blog_post node.')

  const { prismicId, uid, data } = node
  if (!uid || !data) throw new Error('Invalid blog_post nodes.')

  const {
    posttitle,
    postcategory,
    postsecondarycategories,
    posttemplate,
    postdescription,
    postmainimage,
    postthumbnailimage,
    postintroductiontext,
    postdate,
    parentdocument,
    body,
  } = data

  if (
    !posttitle ||
    !posttitle.text ||
    !postcategory ||
    !postsecondarycategories ||
    !postdate ||
    !parentdocument ||
    !parentdocument.uid ||
    !parentdocument.document ||
    !parentdocument.document.data ||
    !parentdocument.document.data.name ||
    !parentdocument.document.data.name.text ||
    !body
  ) {
    throw new Error(`Invalid blog_post node : ${uid}..`)
  }

  return {
    prismicId,
    uid,
    title: posttitle.text,
    categories: getCategories(postcategory, postsecondarycategories),
    template: getTemplate(posttemplate),
    description: postdescription?.html || '',
    mainImage: { alt: postmainimage?.alt || '', url: postmainimage?.url || '' },
    thumbnailImage: { alt: postthumbnailimage?.alt || '', url: postthumbnailimage?.url || '' },
    introductionText: postintroductiontext?.html || '',
    date: postdate,
    parent: {
      uid: parentdocument.uid,
      name: parentdocument.document.data.name.text,
      parent:
        parentdocument.document.data.parentdocument?.uid &&
        parentdocument.document.data.parentdocument?.document?.data?.name?.text
          ? {
              uid: parentdocument.document.data.parentdocument.uid,
              name: parentdocument.document.data.parentdocument.document.data.name.text,
            }
          : undefined,
    },
    slices: body.map((slice) => {
      if (!slice) throw new Error(`Invalid blog_post node : ${uid}..`)

      // TODO: fix using discriminated unions from "gatsby-types-2.ts"
      const { slice_type, primary, items } = slice

      switch (slice_type) {
        case 'title': {
          if (!primary || !primary.posttitle || !primary.posttitle.text) {
            throw new Error(`Invalid blog_post title slice : ${uid}.`)
          }

          return { type: BlogPostSliceType.TITLE, content: { value: primary.posttitle.text } }
        }
        case 'iframe_code': {
          if (!primary) {
            throw new Error(`Invalid blog_post title slice : ${uid}.`)
          }

          return {
            type: BlogPostSliceType.IFRAME,
            content: { html: primary.iframe_code ? primary.iframe_code.text : undefined },
          }
        }
        case 'subtitle': {
          if (!primary || !primary.postsubtitle || !primary.postsubtitle.text) {
            throw new Error(`Invalid blog_post subtitle slice : ${uid}.`)
          }

          return { type: BlogPostSliceType.SUBTITLE, content: { value: primary.postsubtitle.text } }
        }
        case 'text': {
          if (!primary || !primary.posttext || !primary.posttext.html) {
            throw new Error(`Invalid blog_post text slice : ${uid}.`)
          }

          return { type: BlogPostSliceType.TEXT, content: { value: primary.posttext.html } }
        }
        case 'strong_text': {
          if (!primary || !primary.poststrongtext || !primary.poststrongtext.text) {
            throw new Error(`Invalid blog_post strong_text slice : ${uid}.`)
          }

          return { type: BlogPostSliceType.STRONG_TEXT, content: { value: primary.poststrongtext.text } }
        }
        case 'image': {
          if (!primary || !primary.postimage || !primary.postimage.url) {
            throw new Error(`Invalid blog_post image slice : ${uid}.`)
          }

          return {
            type: BlogPostSliceType.IMAGE,
            content: { alt: primary.postimage.alt || '', url: primary.postimage.url },
          }
        }
        case 'video': {
          if (!primary || !primary.postyoutubeembed || !primary.postyoutubeembed.text) {
            throw new Error(`Invalid blog_post video slice : ${uid}.`)
          }

          return {
            type: BlogPostSliceType.VIDEO,
            content: {
              embed: primary.postyoutubeembed.text,
              description: primary.postvideodescription?.text || '',
            },
          }
        }
        case 'audio': {
          if (!primary || !primary.postsoundcloudembed || !primary.postsoundcloudembed.text) {
            throw new Error(`Invalid blog_post audio slice : ${uid}.`)
          }

          return { type: BlogPostSliceType.AUDIO, content: { embed: primary.postsoundcloudembed.text } }
        }
        case 'quote': {
          if (!primary || !primary.postquotevalue || !primary.postquotevalue.text) {
            throw new Error(`Invalid blog_post quote slice : ${uid}.`)
          }

          return {
            type: BlogPostSliceType.QUOTE,
            content: {
              value: primary.postquotevalue.text,
              author: primary.postquoteauthor?.text || '',
            },
          }
        }
        case 'carousel': {
          if (!items) throw new Error(`Invalid blog_post carousel slice : ${uid}.`)

          return {
            type: BlogPostSliceType.CAROUSEL,
            content: {
              items: items.map((item) => {
                if (!item || !item.image || !item.image.url) {
                  throw new Error(`Invalid blog_post carousel slice : ${uid}.`)
                }

                return { alt: item.image.alt || '', url: item.image.url }
              }),
            },
          }
        }
        case 'listed_block': {
          if (
            !primary ||
            !primary.postlistedblockicon ||
            !primary.postlistedblockicon.url ||
            !primary.postlistedblocktitle ||
            !primary.postlistedblocktitle.text ||
            !primary.postlistedblocktext ||
            !primary.postlistedblocktext.text
          ) {
            throw new Error(`Invalid blog_post listed_block slice : ${uid}.`)
          }

          return {
            type: BlogPostSliceType.LISTED_BLOCK,
            content: {
              icon: {
                alt: primary.postlistedblockicon.alt,
                url: primary.postlistedblockicon.url,
              },
              title: primary.postlistedblocktitle.text,
              text: primary.postlistedblocktext.text,
            },
          }
        }
        case 'white_paper': {
          if (
            !primary ||
            !primary.whitepaperuid ||
            !primary.whitepaperuid.text ||
            !primary.postwhitepapertitle ||
            !primary.postwhitepapertitle.text ||
            !primary.postwhitepapertext ||
            !primary.postwhitepapertext.text ||
            !primary.postwhitepaperimage ||
            !primary.postwhitepaperimage.url
          ) {
            throw new Error(`Invalid blog_post white_paper slice : ${uid}.`)
          }

          const documentUrl =
            primary.postwhitepaperdocument && primary.postwhitepaperdocument.url
              ? primary.postwhitepaperdocument.url || ''
              : primary.postwhitepaperdocumentlink?.url || ''

          return {
            type: BlogPostSliceType.WHITE_PAPER,
            content: {
              uid: primary.whitepaperuid.text,
              title: primary.postwhitepapertitle.text,
              text: primary.postwhitepapertext.text,
              image: { alt: primary.postwhitepaperimage.alt || '', url: primary.postwhitepaperimage.url },
              documentUrl,
            },
          }
        }
        case 'button_link': {
          if (!primary || !primary.label || !primary.label.text || !primary.url || !primary.url.url) {
            throw new Error(`Invalid blog_post button_link slice : ${uid}.`)
          }

          return {
            type: BlogPostSliceType.BUTTON_LINK,
            content: {
              label: primary.label.text,
              url: primary.url.url,
              target: primary.url.target || undefined,
            },
          }
        }
        case 'quote_carousel': {
          if (!items) {
            throw new Error(`Invalid blog_post quote_carousel slice : ${uid}.`)
          }

          return {
            type: BlogPostSliceType.QUOTE_CAROUSEL,
            content: {
              quotes: items.map((item) => {
                if (!item && !item.photo && !item.quote && !item.author) {
                  throw new Error(`Invalid blog_post quote_carousel slice : ${uid}.`)
                }

                return {
                  photo: { url: item.photo.url, alt: item.photo.alt || '' },
                  quoteText: item.quote.text,
                  quoteAuthor: item.author.text,
                }
              }),
            },
          }
        }
        case 'logo_list': {
          if (!items) {
            throw new Error(`Invalid blog_post logo_list slice : ${uid}.`)
          }

          return {
            type: BlogPostSliceType.LOGO_LIST,
            content: {
              logos: items.map((item) => {
                if (!item && !item.logo) {
                  throw new Error(`Invalid blog_post logo_list slice : ${uid}.`)
                }

                return {
                  image: { url: item.logo.url, alt: item.logo.alt || '' },
                  link: item.link ? { target: item.link.target || undefined, url: item.link.url } : undefined,
                }
              }),
            },
          }
        }
        case 'footer_cards': {
          if (!items) throw new Error(`Invalid blog_post footer_cards slice : ${uid}.`)

          return {
            type: BlogPostSliceType.FOOTER_CARDS,
            content: items.map((item) => {
              if (!item) throw new Error(`Invalid blog_post footer_cards slice : ${uid}.`)

              const {
                footercardtitle,
                footercardsubtitle,
                footercardtext,
                footercardlinklabel,
                footercardlinkurl,
                footercardbackground,
              } = item

              if (
                !footercardtitle ||
                !footercardtitle.text ||
                !footercardsubtitle ||
                !footercardsubtitle.text ||
                !footercardtext ||
                !footercardtext.text ||
                !footercardlinklabel ||
                !footercardlinklabel.text ||
                !footercardlinkurl ||
                !footercardlinkurl.url ||
                !footercardbackground ||
                !footercardbackground.url
              ) {
                throw new Error(`Invalid blog_post footer_cards slice : ${uid}.`)
              }

              return {
                title: footercardtitle.text,
                subtitle: footercardsubtitle.text,
                text: footercardtext.text,
                link: { label: footercardlinklabel.text, url: footercardlinkurl.url },
                background: { alt: '', url: footercardbackground.url },
              }
            }),
          }
        }
        default: {
          throw new Error(`Invalid blog_post node : ${uid}. ${slice_type} does not exist.`)
        }
      }
    }),
  }
}

function getCategories(
  mainCategory: string,
  secondaryCategories: Array<Maybe<Pick<PrismicBlogPostPostsecondarycategoriesGroupType, 'category'>>>
): string[] {
  const categories = [mainCategory]

  for (const secondaryCategory of secondaryCategories) {
    if (secondaryCategory && secondaryCategory.category) {
      categories.push(secondaryCategory.category)
    }
  }

  return categories
}

function getTemplate(template: string | null | undefined): IBlogPostTemplate | undefined {
  switch (template) {
    case 'EdMill':
      return { type: BlogPostTemplate.EDMILL, color: '#93235f' }
    case 'IFSImulation':
      return { type: BlogPostTemplate.IFSIMULATION, color: '#21979d' }
    case 'Santé':
      return { type: BlogPostTemplate.IFSIMULATION, color: '#21979d' }
    default:
      return undefined
  }
}
